import * as Types from './types';

export const setIsMobile = isMobile => ({
  type: Types.SET_IS_MOBILE,
  payload: isMobile
});

export const setIsWebp = isWebp => ({
  type: Types.SET_IS_WEBP,
  payload: isWebp
});

export const setIsGoogleMaps = isGoogleMaps => ({
  type: Types.SET_IS_GOOGLE_MAPS,
  payload: isGoogleMaps
});

export const setIs404 = is404 => ({
  type: Types.SET_IS_404,
  payload: is404
});

export const setCookiesAccepted = () => ({
  type: Types.SET_COOKIES_ACCEPTED
});

export const hideScholarshipAlert = () => ({
  type: Types.SCHOLARSHIP_HIDE_ALERT
});
