import ballotConfig from 'config/ballot';
import { USER, USER_MAP } from 'config/core';
import accountStatuses from 'config/dictionaries/account-statuses';
import scholarshipConfig from 'config/scholarship';
import dateUtcToZonedTime from 'date-fns-tz/utcToZonedTime';
import dateIsWithinInterval from 'date-fns/isWithinInterval';
import dateParse from 'date-fns/parse';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';

import { dateIsWithingPendingPeriod } from './dates';
import { mergeByMap, pick, pickAll } from './helpers';

export function userHasVoted(userData) {
  const hasVoted = _get(userData, 'hasVoted');
  return hasVoted === '1';
}

export function isScholarshipPeriod() {
  const startDate = dateParse(
    scholarshipConfig.startDate,
    scholarshipConfig.dateFormatString,
    new Date()
  );

  const endDate = dateParse(
    scholarshipConfig.endDate,
    scholarshipConfig.dateFormatString,
    new Date()
  );

  const tzNow = dateUtcToZonedTime(new Date(), scholarshipConfig.tz);

  return dateIsWithinInterval(tzNow, {
    start: startDate,
    end: endDate
  });
}

export function isBallotPeriod() {
  const startDate = dateParse(
    ballotConfig.startDate,
    ballotConfig.dateFormatString,
    new Date()
  );

  const endDate = dateParse(
    ballotConfig.endDate,
    ballotConfig.dateFormatString,
    new Date()
  );

  const tzNow = dateUtcToZonedTime(new Date(), ballotConfig.tz);

  return dateIsWithinInterval(tzNow, {
    start: startDate,
    end: endDate
  });
}

export function ballotAllowed(userData) {
  const ballotPeriod = isBallotPeriod();

  if (!ballotPeriod) {
    return false;
  }

  const mbaError = _get(userData, 'mbaError');

  if (mbaError) {
    return false;
  }

  const userAccountStatus = _get(userData, 'accountStatus');

  const statusAllowed =
    (getBallotAllowedStatuses() || []).indexOf(userAccountStatus) > -1;

  if (!statusAllowed) {
    return false;
  }

  return true;
}

export function getBallotAllowedStatuses() {
  return pickAll(accountStatuses, { ballot: true }, 'code') || [];
}

export function getPaymentAllowedStatuses() {
  return pickAll(accountStatuses, { inForce: true }, 'code') || [];
}

export function isNationalGudardMember(affiliation) {
  if (!affiliation) {
    return false;
  }

  /*
    Checked by affiliation code:
      MAS-WI
      MAS-MA
      MAS-WI
  */

  const regExp = /\bmas\b/i;

  return regExp.test(affiliation);
}

export function isAffiliationPaymentAllowed(affiliation) {
  if (!affiliation) {
    return true;
  }

  // National Guard members not allowed to access payment page.
  return !isNationalGudardMember(affiliation);
}

export function paymentAllowed(userData) {
  const mbaError = _get(userData, 'mbaError');

  if (mbaError) {
    return false;
  }

  const userAccountStatus = _get(userData, 'accountStatus');
  const statusAllowed =
    (getPaymentAllowedStatuses() || []).indexOf(userAccountStatus) > -1;

  const userAffiliationCode = _get(userData, 'affiliation');
  const affiliationAllowed = isAffiliationPaymentAllowed(userAffiliationCode);

  return statusAllowed && affiliationAllowed;
}

export function paymentMethodUpdatePending(userData) {
  const paymentMethodLastUpdated = _get(userData, 'paymentMethodLastUpdated');
  return dateIsWithingPendingPeriod(
    paymentMethodLastUpdated,
    USER.udpatePaymentMethodPandingPeriod
  );
}

export function beneUpdatePending(userData) {
  const beneLastUpdated = _get(userData, 'beneLastUpdated');
  return dateIsWithingPendingPeriod(
    beneLastUpdated,
    USER.udpateBenePandingPeriod
  );
}

export function nationalGuardCertAllowed(userData) {
  const userAffiliationCode = _get(userData, 'affiliation');

  return isNationalGudardMember(userAffiliationCode);
}

// move applications of specific type in the separate collection
export function normalizeApplicationsByType(applications, policies) {
  const result = {};
  for (const key in applications) {
    if (applications.hasOwnProperty(key)) {
      result[key] = !Array.isArray(applications[key])
        ? applications[key]
        : applications[key].filter(item => {
            const type = _get(item, 'policy.type', '');
            const typeName = pick(policies, { code: type }, 'type', '');
            if (typeName) {
              if (item.status !== 'rejected') {
                if (result[typeName]) {
                  result[typeName].push(item);
                } else {
                  result[typeName] = [item];
                }
              }
              return false;
            }
            return true;
          });
    }
  }
  return result;
}

/**
 * Returns list of submitted (but not rejected) apps
 */
export function getSubmittedApplications(applications) {
  const result = [];
  if (!applications) {
    return result;
  }
  for (const key in applications) {
    if (applications.hasOwnProperty(key)) {
      const collection = applications[key];
      if (Array.isArray(collection) && collection.length) {
        collection.forEach(app => {
          if (
            app &&
            app.policy &&
            app.policy.type &&
            app.status &&
            app.status !== 'rejected'
          ) {
            result.push(app.policy.type);
          }
        });
      }
    }
  }
  return _uniq(result);
}

export function getBeneficiariesFromApplications(applications, key) {
  return !applications ||
    !key ||
    !applications[key] ||
    !Array.isArray(applications[key])
    ? null
    : applications[key].reduce((memo, current) => {
        const { beneficiaries } = current || {};
        if (beneficiaries) {
          for (const beneType in beneficiaries) {
            if (beneficiaries.hasOwnProperty(beneType)) {
              if (beneficiaries[beneType] && beneficiaries[beneType].length) {
                if (!memo) memo = {};
                if (!memo[beneType]) memo[beneType] = [];
                memo[beneType] = _uniq(
                  memo[beneType].concat(
                    beneficiaries[beneType].map(({ name }) => name)
                  )
                );
              }
            }
          }
        }
        return memo;
      }, null);
}

export function mergeUserValues(targetObj, targetMap, userObj) {
  const userMap = USER_MAP;
  return mergeByMap(targetObj, targetMap, userObj, userMap);
}
