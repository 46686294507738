const scholarshipConfig = {
  // TODO: change to 2023
  startDate: '12/02/2024 06:00',
  endDate: '02/27/2025 15:00',
  tz: 'America/Chicago',
  dateFormatString: 'MM/dd/yyyy HH:mm',
  applyLink: 'https://learnmore.scholarsapply.org/militarybenefit/'
};

export default scholarshipConfig;
