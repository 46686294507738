import apoFpoStates from 'config/dictionaries/apo-fpo-states';
import branchOfServices from 'config/dictionaries/branch-of-services';
import countries from 'config/dictionaries/countries';
import creditCardTypes from 'config/dictionaries/credit-card-types';
import dutyStatuses from 'config/dictionaries/duty-statuses';
import frequencies from 'config/dictionaries/frequencies';
import genders from 'config/dictionaries/genders';
import memberStatuses from 'config/dictionaries/member-statuses';
import paymentPurchaseTypes from 'config/dictionaries/payment-purchase-types';
import paymentTypes from 'config/dictionaries/payment-types';
import ranks from 'config/dictionaries/ranks';
import relationshipStatuses from 'config/dictionaries/relationship-statuses';
import states from 'config/dictionaries/states';
import typeOfPayes from 'config/dictionaries/type-of-pays';
import ErrorStrings from 'config/error-strings';
import dateAdd from 'date-fns/add';
import dateEndOfMonth from 'date-fns/endOfMonth';
import dateIsExists from 'date-fns/isExists';
import dateIsWithinInterval from 'date-fns/isWithinInterval';
import { createRankFullValue } from 'helpers/helpers';
import _concat from 'lodash/concat';
import { number, object, string } from 'yup';

import { beneLengthValid } from './helpers';

export function onlyNumbers() {
  return string().matches(/^\d*$/, {
    excludeEmptyString: true,
    message: ErrorStrings.onlyNumbers
  });
}

export function firstName() {
  return strictString().max(15);
}

export function middleInitial() {
  return strictString().max(1);
}

export function lastName() {
  return strictString().max(20);
}

export function date() {
  return string().matches(
    /^(((0[13578]|1[02])\/(0[1-9]|[12]\d|3[01])\/((19|[2-9]\d)\d{2}))|((0[13456789]|1[012])\/(0[1-9]|[12]\d|30)\/((19|[2-9]\d)\d{2}))|(02\/(0[1-9]|1\d|2[0-8])\/((19|[2-9]\d)\d{2}))|(02\/29\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/,
    {
      excludeEmptyString: true,
      message: ErrorStrings.dateField
    }
  );
}

export function ssn() {
  return string()
    .matches(/^(?!666|900)[0-8][0-9]{2}-[0-9]{2}-(?!0000)[0-9]{4}$/, {
      excludeEmptyString: true,
      message: ErrorStrings.ssnField
    })
    .matches(
      /^(?!000-00-0000|111-11-1111|222-22-2222|333-33-3333|444-44-4444|555-55-5555|666-66-6666|777-77-7777|888-88-8888|999-99-9999|123-45-6789|219-09-9999|987-65-4320|987-65-4329).*$/,
      {
        excludeEmptyString: true,
        message: ErrorStrings.ssnNotValid
      }
    );
}

export function street() {
  return strictString().max(30);
}

export function address2() {
  return strictString().max(30);
}

export function zip() {
  return string().matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, {
    excludeEmptyString: true,
    message: ErrorStrings.zipPattern
  });
}

export function city() {
  return strictString().max(22);
}

export function state(args) {
  const { notAllowedStates } = args || {};
  let schema = string().oneOf(
    _concat(apoFpoStates, states).map(({ code }) => code)
  );
  if (notAllowedStates && notAllowedStates.length) {
    schema = schema.notOneOf(notAllowedStates);
  }
  return schema;
}

export function country() {
  return string().oneOf(countries.map(({ code }) => code));
}

export function phone() {
  return string().matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, {
    excludeEmptyString: true,
    message: ErrorStrings.phonePattern
  });
}

export function phonePlain() {
  return string()
    .length(10)
    .matches(/^[0-9]{10}/, {
      excludeEmptyString: true,
      message: ErrorStrings.plainPhonePattern
    });
}

export function email() {
  return string().email().max(60);
}
export function gender() {
  return string().oneOf(genders.map(({ code }) => code));
}

export function typeOfPay() {
  return string().oneOf(typeOfPayes.map(({ code }) => code));
}

export function relationshipStatus() {
  return string().oneOf(relationshipStatuses.map(({ code }) => code));
}

export function dutyStatus(statuses) {
  return string().oneOf((statuses || dutyStatuses).map(({ code }) => code));
}

export function branchOfService(branches) {
  return string().oneOf((branches || branchOfServices).map(({ code }) => code));
}

export function rank() {
  return string().oneOf(ranks.map(({ code }) => code));
}

export function rankFull() {
  return string().oneOf(
    ranks.map(({ branch, code }) => createRankFullValue(branch, code))
  );
}

export function rankPlain() {
  return string().max(70);
}

export function userName() {
  return strictString().min(5);
}

export function password() {
  return string().min(6);
}

export function passwordConfirm(ref) {
  return string().oneOf([ref, null], ErrorStrings.confirmPasswordField);
}

export function strictString() {
  return string().matches(/^[A-Za-z0-9À-ÖØ-öø-ÿ _\-'.,&*()?;:%#@!+]*$/, {
    excludeEmptyString: true,
    message: ErrorStrings.strictStringField
  });
}

export function creditCardType() {
  return string().oneOf(creditCardTypes.map(({ code }) => code));
}

export function creditCardNumber() {
  return number().test(
    'isValidCCNumber',
    ErrorStrings.creditCardNumberField,
    value => {
      let valid = true;
      value = value && String(value);
      if (value !== undefined) {
        let i = value.length;
        let sum = 0;
        let mul = 1;
        while (i--) {
          const char = value.charAt(i) * mul;
          sum = sum + (char - (char > 9) * 9);
          mul = mul ^ 3;
        }
        valid = sum % 10 === 0 && sum > 0;
      }
      return valid;
    }
  );
}

export function creditCardExpirationDate() {
  return string()
    .matches(/^\d{2}\/\d{4}$/, {
      excludeEmptyString: true,
      message: ErrorStrings.creditCardExpirationDatePattern
    })
    .test(
      'isValidCCExpDate',
      ErrorStrings.creditCardExpirationDateField,
      value => {
        let valid = true;
        if (value !== undefined) {
          const dateParts = value.split('/');
          const month = parseInt(dateParts[0]) - 1;
          const year = parseInt(dateParts[1]);
          if (!dateIsExists(year, month, 1)) return false;
          const epxDate = dateEndOfMonth(new Date(year, month));
          const today = new Date();
          const maxDate = dateAdd(today, { years: 100 });
          valid = dateIsWithinInterval(epxDate, {
            start: today,
            end: maxDate
          });
        }
        return valid;
      }
    );
}

export function cvv() {
  return onlyNumbers().min(3).max(4);
}

export function switcher(values) {
  return string().oneOf(values || ['1', '0']);
}

export function confirmation(value) {
  return string().oneOf(
    value !== undefined ? [value] : ['1'],
    ErrorStrings.requiredField
  );
}

export function postalCode() {
  return strictString().max(9);
}

export function frequency() {
  return string().oneOf(frequencies.map(({ code }) => code));
}

export function eftBankName() {
  return strictString().max(50);
}

export function eftRoutingNumber() {
  return onlyNumbers().length(9);
}

export function eftAccountNumber() {
  return onlyNumbers().min(6).max(17);
}

export function tobacco() {
  return object({
    isConfirmed: switcher().required()
  });
}

export function id() {
  return string()
    .max(100)
    .matches(/^[0-9a-zA-Z_]*$/, {
      excludeEmptyString: true,
      message: ErrorStrings.id
    });
}

export function appName() {
  return strictString().max(100);
}

export function memberStatus() {
  return string().oneOf(memberStatuses.map(({ code }) => code));
}

export function paymentType() {
  return string().oneOf(paymentTypes.map(({ code }) => code));
}

export function paymentPurchaseType() {
  return string().oneOf(paymentPurchaseTypes.map(({ code }) => code));
}

export function unit() {
  return strictString().max(500);
}

export function utmValue() {
  return strictString();
}

export function coverage() {
  return number().integer();
}

export function confirmedSwitcher(args) {
  const { confirmedRequired = false } = args || {};

  return object({
    isConfirmed: confirmedRequired ? switcher().required() : switcher()
  });
}

export function checkBeneLength(benesYupObj) {
  return benesYupObj.test('checkBeneResultMaxLength', '', function ({
    primary = [],
    contingent = []
  }) {
    const { error } = beneLengthValid([...primary, ...contingent]);

    if (error) {
      const { path, createError } = this;

      return new createError({
        path: `${path}.beneResultingStringMaxLength`,
        message: error
      });
    }

    return true;
  });
}

export function emptyString() {
  return string().oneOf(['']);
}
